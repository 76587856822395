import { Injectable, inject } from '@angular/core';
import { Instance } from '../components/instance-selector/instance-selector.http.service';
import { AuthConfigService } from '@auth0/auth0-angular';
import { config } from '../consts';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private authConfig = inject(AuthConfigService);

  public setApiBaseUrl(instance: Instance) {
    config.apiBaseUrl = instance.url;
    config.instance = instance;
    this.updateAllowedList();
  }

  public updateAllowedList() {
    const allowedList = this.authConfig.httpInterceptor?.allowedList;
    if (allowedList && config.apiBaseUrl) {
      allowedList.splice(0, allowedList.length - 1);
      allowedList.push(config.apiBaseUrl + '/*');
      console.log('Allowed list updated', allowedList);
    }
  }
}

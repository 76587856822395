<h1 mat-dialog-title>Generate link</h1>
<div mat-dialog-content>
  <p>
    Enter the details of the person you wish to send a link for this procedure
    to:
  </p>
  <app-procedure-details-panel
    [procedure]="data.procedure"></app-procedure-details-panel>
  <form [formGroup]="form">
    <h4>Recipient details</h4>
    <mat-form-field>
      <mat-label>Name:</mat-label>
      <input
        type="text"
        placeholder="Enter name"
        matInput
        formControlName="name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Email address:</mat-label>
      <input
        type="email"
        placeholder="Enter email"
        matInput
        formControlName="email" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Mobile number:</mat-label>
      <input
        type="tel"
        placeholder="Enter mobile number"
        matInput
        formControlName="phone_number" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Access link type</mat-label>
      <mat-select formControlName="one_time">
        <mat-option [value]="true"> Single-use access link </mat-option>
        <mat-option [value]="false"> Time-restricted access link </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="showExpirySelect()">
      <mat-label>Access link expiry</mat-label>
      <mat-select formControlName="expiry">
        <mat-option [value]="1"> 1 hour </mat-option>
        <mat-option [value]="6"> 6 hours </mat-option>
        <mat-option [value]="12"> 12 hours </mat-option>
        <mat-option [value]="24"> 24 hours </mat-option>
        <mat-option [value]="48"> 48 hours </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox formControlName="acknowledge" [style.marginTop.rem]="1">
      <b>
        I confirm that I have permission to grant access to this patient's
        procedure to the specified recipient.
      </b>
    </mat-checkbox>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!form.valid"
    (click)="generateLink()">
    Transfer
  </button>
</div>

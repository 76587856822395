// Transfers
export type Transfer = {
  id: number;
  procedure: Procedure;
  to_site: {
    name: string;
  };
  approved: boolean;
  approval_user: {
    full_name: string;
  } | null;
  progress_count: number;
  status: TransferStatus;
  progress_percent?: number;
  created_date: string;
  relative_time?: string;
};

export enum TransferStatus {
  PendingApproval,
  Pending,
  InProgress,
  Completed,
  Failed,
  Cancelled,
}

export type TransferParams = {
  procedure: number;
  to_site: number;
  approval_user: number | null;
  delete: boolean;
  acknowledge: boolean;
};

export type ApprovalUser = {
  id: number;
  full_name: string;
};

// Sites
export type SiteApprovalRequired = {
  id: number;
  name: string;
  sites_see: UserSite[];
  approval_required: true;
  approval_users: ApprovalUser[];
};

export type SiteApprovalNotRequired = {
  id: number;
  name: string;
  sites_see: UserSite[];
  approval_required: false;
  approval_users: null;
};

export type UserSite = {
  id: number;
  name: string;
};

export type Site = SiteApprovalRequired | SiteApprovalNotRequired;

// Procedures
export type ProcedureFromUserSite = {
  id: number;
  date: string;
  description: string;
  modality: string;
  accession_number: string;
  patient_name: string;
  patient_id: string;
  patient_dob: string;
  patient_sex: string;
  procedure_is_from_user_site: true;
  site: {
    id: number;
    name: string;
  };
  image_count: number;
};

export type ProcedureNotFromUserSite = {
  id: number;
  date: string;
  description: string;
  modality: string;
  accession_number: string;
  patient_name: string;
  patient_id: string;
  patient_dob: string;
  patient_sex: string;
  procedure_is_from_user_site: false;
  site: Site;
  image_count: number;
};

export type Procedure = ProcedureFromUserSite | ProcedureNotFromUserSite;

export type FormattedProcedure = Procedure & {
  f_date: string;
  f_patient_dob: string;
};

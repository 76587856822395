import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { FileSizePipe } from 'src/app/pipes/file-size.pipe';
import { IsDicomService } from 'src/app/services/is-dicom.service';
import { FormsModule } from 'src/app/shared/forms.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploadHttpService } from './upload.http.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpEventType } from '@angular/common/http';
import {
  Observable,
  Subject,
  catchError,
  forkJoin,
  map,
  take,
  takeUntil,
} from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { UserService } from 'src/app/services/user.service';
import { MatSelectModule } from '@angular/material/select';

type ScannedFile = {
  name: string;
  type?: string;
  size: number;
  progress?: number;
  error?: boolean;
};

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [
    SharedModule,
    FormsModule,
    MatDialogModule,
    MatListModule,
    FileSizePipe,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSelectModule,
  ],
  templateUrl: `./upload.component.html`,
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  private isDicom = inject(IsDicomService);
  private uploadHttp = inject(UploadHttpService);
  private destroyRef = inject(DestroyRef);
  private snackbar = inject(SnackbarService);
  private dialogRef = inject(MatDialogRef);
  private confirmationDialog = inject(ConfirmationDialogService);
  public userService = inject(UserService);

  public fileList: FileList | null = null;
  public validFiles: ScannedFile[] = [];
  public invalidFiles: ScannedFile[] = [];
  public processing = false;
  public acknowledgement = false;
  public site: number | undefined;
  public isUploading = false;

  public cancelUpload = new Subject<void>();

  ngOnInit(): void {
    this.site =
      this.userService.userPermissions$.value?.upload_procedures_sites?.[0].id;
    if (!this.site) {
      this.snackbar.showCustomError(
        'An error occurred while trying to upload files. Please refresh the page and try again.'
      );
      this.dialogRef.close();
    }
  }

  public async onFilesChanged(event: Event) {
    this.processing = true;
    [this.validFiles, this.invalidFiles] = [[], []];
    this.fileList = null;

    const files = (event.target as HTMLInputElement).files as FileList;

    if (!files.length) return;

    for (let i = 0; i < files.length; i++) {
      const isDicomFile = await this.isDicom.isDicomFile(files[i]);
      if (!isDicomFile) {
        this.invalidFiles.push({
          name: files[i].name,
          type: files[i].type,
          size: files[i].size,
        });
        continue;
      }
      this.validFiles.push({
        name: files[i].name,
        size: files[i].size,
      });
      this.fileList = files;
    }
    this.processing = false;
  }

  public upload() {
    if (!this.validFiles.length || !this.acknowledgement || !this.site) return;
    this.uploadHttp
      .getUploadURL({
        acknowledge: this.acknowledgement,
        site: this.site,
      })
      .pipe(take(1))
      .subscribe({
        next: url => {
          if (!url.length) {
            this.snackbar.showCustomError(
              'Something went wrong, please try again.'
            );
            return;
          }
          console.log(url);
          const request = this.startUpload(url);
          if (!request) return;
          this.isUploading = true;
          request.pipe(take(1)).subscribe({
            next: response => {
              if (!response.some(result => !result)) {
                this.snackbar.showSuccess('Upload complete. Please allow up to 5 minutes for the procedure(s) to be available.');
                this.dialogRef.close();
              }
            },
            error: () => {
              this.snackbar.showCustomError(
                'Something went wrong during the upload.'
              );
            },
          });
        },
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private startUpload(url: string): Observable<any[]> | void {
    if (!this.fileList?.length) {
      this.snackbar.showCustomError('No files to upload');
      return;
    }
    const totalTransfer = this.validFiles.reduce(
      (acc, curr) => acc + curr.size,
      0
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const uploadObservables: Observable<any>[] = [];
    for (let i = 0; i < this.fileList.length; i++) {
      const matchedFile = this.validFiles.find(
        file => file.name === this.fileList?.[i]?.name
      );
      uploadObservables.push(
        this.uploadHttp
          .postUpload(this.fileList[i], url, this.generateUniqueBlobName())
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            takeUntil(this.cancelUpload),
            map(event => {
              switch (event.type) {
                case HttpEventType.Sent:
                  if (!matchedFile) return;
                  matchedFile.progress = 0;
                  return false;
                case HttpEventType.UploadProgress:
                  if (!matchedFile) return;
                  matchedFile.progress = Math.round(
                    (100 * event.loaded) / (event.total || totalTransfer)
                  );
                  return false;
                case HttpEventType.Response:
                  if (!matchedFile) return;
                  matchedFile.progress = 100;
                  return true;
                default:
                  return false;
              }
            }),
            catchError(error => {
              // Handle the error here. For example, you could update the UI to indicate that the upload failed.
              if (matchedFile) {
                matchedFile.error = true;
              }
              throw error;
            })
          )
      );
    }
    return forkJoin<boolean[]>(uploadObservables);
  }

  private generateUniqueBlobName(): string {
    // Replace this with your own logic to generate a unique blob name
    return `${Date.now()}-${Math.random().toString(36).substring(2)}`;
  }

  public cancelUploads() {
    this.cancelUpload.next();
    if (this.validFiles.some(file => file.progress === 100)) {
      const result = this.confirmationDialog.confirm({
        title: 'Uploaded files will not be deleted',
        message:
          'Although the upload was cancelled, some files were already finished uploading. If you wish to delete this procedure, you can do so from the procedures list.',
        confirmButton: 'I understand',
      });

      result.pipe(take(1)).subscribe({
        next: () => {
          this.dialogRef.close();
        },
      });
    }
  }
}

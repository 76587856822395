import { Injectable, inject } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private user = inject(UserService);

  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setData(key: string, value: any, withUser: boolean = true): void {
    console.log('set attempt', this.user.authUser$?.value?.['sub']);
    if (withUser && this.user.authUser$?.value?.['sub']) {
      key = `${this.user.authUser$.value['sub']}_${key}`;
    }
    console.log('set', key, value, withUser);
    localStorage.setItem(key, JSON.stringify(value));
  }

  // Method to retrieve data from local storage
  getData<T>(key: string, withUser: boolean = true): T | null {
    if (withUser && this.user.authUser$?.value?.['sub']) {
      key = `${this.user.authUser$.value['sub']}_${key}`;
    }
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data) as T;
    }
    return null;
  }

  // Method to delete data from local storage
  deleteData(key: string, withUser: boolean = true): void {
    if (withUser && this.user.authUser$?.value?.['sub']) {
      key = `${this.user.authUser$.value['sub']}_${key}`;
    }
    localStorage.removeItem(key);
  }
}

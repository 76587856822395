import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Procedure, Site, TransferParams } from './procedures.model';
import { Endpoints } from 'src/app/consts';
import { config } from 'src/app/consts';

@Injectable({
  providedIn: 'root',
})
export class ProceduresHttpService {
  private http = inject(HttpClient);

  public getProcedures(): Observable<Procedure[]> {
    return this.http.get<Procedure[]>(config.apiBaseUrl + Endpoints.Procedures);
  }

  public getProcedure(id: number): Observable<Procedure> {
    return this.http.get<Procedure>(
      config.apiBaseUrl + Endpoints.Procedures + id
    );
  }

  public getSites(): Observable<Site[]> {
    return this.http.get<Site[]>(config.apiBaseUrl + '/sites');
  }

  public sendProcedure(params: TransferParams): Observable<HttpResponseBase> {
    return this.http.post<HttpResponseBase>(
      config.apiBaseUrl + Endpoints.Transfers,
      params,
      { observe: 'response' }
    );
  }

  public deleteProcedure(id: number): Observable<HttpResponseBase> {
    return this.http.delete<HttpResponseBase>(
      `${config.apiBaseUrl}${Endpoints.Procedures}${id}`,
      { observe: 'response' }
    );
  }
}

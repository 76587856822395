<ng-container [ngSwitch]="data.type">
  <ng-container *ngSwitchCase="SnackBarType.Error">
    <mat-icon class="material-icons-round snackbar-error"
      >priority_high</mat-icon
    >
    <span matSnackBarLabel>
      {{ data.message }}
    </span>
    <span matSnackBarActions *ngIf="data.show_button">
      <button
        mat-button
        matSnackBarAction
        (click)="snackBarRef.dismissWithAction()">
        OK
      </button>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="SnackBarType.CustomError">
    <mat-icon class="material-icons-round snackbar-error"
      >priority_high</mat-icon
    >
    <span matSnackBarLabel>
      {{ data.message }}
    </span>
    <span matSnackBarActions *ngIf="data.show_button">
      <button
        mat-button
        matSnackBarAction
        (click)="snackBarRef.dismissWithAction()">
        OK
      </button>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="SnackBarType.Success">
    <mat-icon class="material-icons-round snackbar-success">done</mat-icon>
    <span>
      {{ data.message }}
    </span>
    <span matSnackBarActions *ngIf="data.show_button">
      <button
        mat-button
        matSnackBarAction
        (click)="snackBarRef.dismissWithAction()">
        OK
      </button>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="SnackBarType.Info">
    <mat-icon class="material-icons-round snackbar-info">lightbulb</mat-icon>
    <span matSnackBarLabel>
      {{ data.message }}
    </span>
    <span matSnackBarActions *ngIf="data.show_button">
      <button
        mat-button
        matSnackBarAction
        (click)="snackBarRef.dismissWithAction()">
        OK
      </button>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="SnackBarType.Loading">
    <mat-spinner diameter="24" color="accent"></mat-spinner>
    <span matSnackBarLabel>
      {{ data.message }}
    </span>
    <span matSnackBarActions *ngIf="data.show_button">
      <button
        mat-button
        matSnackBarAction
        (click)="snackBarRef.dismissWithAction()">
        OK
      </button>
    </span>
  </ng-container>
</ng-container>

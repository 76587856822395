import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, take } from 'rxjs';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  confirm(data: {
    title: string;
    message: string;
    confirmButton: string;
    cancelButton?: string;
    isDestructive?: boolean;
  }): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: data.title,
        message: data.message,
        confirmButton: data.confirmButton,
        cancelButton: data.cancelButton,
        isDestructive: data.isDestructive,
      },
    });

    return dialogRef.afterClosed().pipe(take(1));
  }
}

<mat-toolbar>
  <span>Transfers</span>
  <span class="spacer"></span>
  <div class="button-group">
    <button
      mat-icon-button
      (click)="refreshTransfers()"
      aria-label="Refresh transfers">
      <mat-icon class="material-icons-round">refresh</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="closeTray()"
      aria-label="Close transfers tray">
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
</mat-toolbar>
<div class="transfers-container">
  <ng-container *ngIf="!loading; else loader">
    <ng-container *ngIf="transfers?.length; else emptyState">
      <mat-card *ngFor="let transfer of transfers">
        <mat-card-header>
          <mat-card-title>{{
            transfer.procedure.patient_name || '[Deleted patient]'
          }}</mat-card-title>
          <mat-card-subtitle
            >{{ transfer.procedure.patient_id || 'N/A' }}<br
          /></mat-card-subtitle>
          <small class="text-secondary">{{ transfer.relative_time }}</small>
        </mat-card-header>
        <mat-card-content>
          <p [style.marginBottom.px]="0">
            {{ transfer.procedure.site.name }} -> {{ transfer.to_site.name }}
          </p>
          <p class="transfer-status" [ngSwitch]="transfer.status">
            <span *ngSwitchCase="TransferStatus.Failed" [style.color]="'red'"
              >Failed</span
            >
            <span *ngSwitchCase="TransferStatus.Cancelled" [style.color]="'red'"
              >Cancelled</span
            >
            <span
              *ngSwitchCase="TransferStatus.InProgress"
              [style.color]="'gray'"
              >{{ transfer.progress_count }}/{{
                transfer.procedure.image_count
              }}</span
            >
            <span
              *ngSwitchCase="TransferStatus.Completed"
              [style.color]="'green'"
              >Completed</span
            >
            <span *ngSwitchCase="TransferStatus.Pending" [style.color]="'gray'"
              >Pending</span
            >
            <span
              *ngSwitchCase="TransferStatus.PendingApproval"
              [style.color]="'gray'"
              >Pending approval by
              {{ transfer.approval_user?.full_name || 'N/A' }}</span
            >
          </p>
        </mat-card-content>
        <mat-card-footer>
          <mat-progress-bar
            [class]="
              [
                'pending',
                'pending',
                'in-progress',
                'completed',
                'failed',
                'failed'
              ][transfer.status]
            "
            mode="determinate"
            [value]="transfer.progress_percent"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </ng-container>
  </ng-container>
</div>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #emptyState>
  <p class="empty-state-text">Nothing to show.</p>
</ng-template>

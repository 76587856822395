import {
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ProceduresHttpService } from '../../procedures.http.service';
import { FormattedProcedure } from '../../procedures.model';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProcedureDetailsPanelComponent } from 'src/app/components/procedure-details-panel/procedure-details-panel.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-generated-link-modal',
  standalone: true,
  imports: [
    SharedModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatInputModule,
    ProcedureDetailsPanelComponent,
  ],
  templateUrl: './generate-link.modal.component.html',
  styleUrls: ['./generate-link.modal.component.scss'],
})
export class GenerateLinkModalComponent implements OnInit {
  public form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    phone_number: new FormControl<string>('', [Validators.required]),
    one_time: new FormControl<boolean>(true, [Validators.required]),
    expiry: new FormControl<number>(1),
    acknowledge: new FormControl<boolean>(false, [Validators.requiredTrue]),
  });

  private proceduresHttpService = inject(ProceduresHttpService);
  private destroyRef = inject(DestroyRef);
  private dialogRef = inject(MatDialogRef<GenerateLinkModalComponent>);
  private snackbar = inject(SnackbarService);

  public procedure: FormattedProcedure;
  public showExpirySelect = signal(false);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      procedure: FormattedProcedure;
    }
  ) {
    this.procedure = data.procedure;
  }

  ngOnInit(): void {
    this.form.controls.one_time.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value === null) return;
        this.showExpirySelect.set(!value);
        if (value) {
          this.form.controls.expiry.setValidators(null);
        } else {
          this.form.controls.expiry.setValidators([Validators.required]);
        }
      });
  }

  public generateLink(): void {
    if (this.form.invalid) {
      this.snackbar.showCustomError(
        'Please correct errors in the form before submitting.'
      );
      return;
    }
    console.log(this.form.value);
  }
}

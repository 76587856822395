<mat-card *ngIf="!loading(); else loader">
  <mat-card-content>
    <div class="select-instance-header">
      <div>
        <img src="logo.png" alt="" />
        <h1>DICOM Hub</h1>
      </div>
      <button
        [style.marginLeft]="'auto'"
        mat-stroked-button
        (click)="user.logout()">
        Sign out
      </button>
    </div>
    <p class="welcome-text">Welcome{{ ', ' + user.authUser$.value?.name }}!</p>
    <h2>Select a server to connect to:</h2>
    <div class="instances">
      <button
        *ngFor="let instance of instances$ | async"
        [class.error]="instance.error"
        [style.order]="instance.error ? '1' : '0'"
        [attr.aria-label]="instance.name"
        class="instance"
        (click)="!instance.loading && setInstance(instance)">
        <img *ngIf="!instance.loading" [src]="instance.logo" alt="" />
        <app-loader *ngIf="instance.loading"></app-loader>
        <span>{{ instance.name }}</span>
      </button>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #loader>
  <div class="loading-wrapper">
    <app-loader></app-loader>
  </div>
</ng-template>

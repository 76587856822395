import { Component, Input, inject } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from 'src/app/shared/shared.module';
import { TransfersService } from '../procedures/transfers/transfers.service';
import { AuthService } from '@auth0/auth0-angular';
import { config } from 'src/app/consts';
import { Router } from '@angular/router';
import { InstanceService } from 'src/app/services/instance.service';
import { UserService } from 'src/app/services/user.service';
import { ThemeService } from 'src/app/services/theme.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    SharedModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonToggleModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public auth = inject(AuthService);
  public transfersService = inject(TransfersService);
  private router = inject(Router);
  private instance = inject(InstanceService);
  public userService = inject(UserService);
  private themeService = inject(ThemeService);

  public currentTheme$ = this.themeService.currentTheme$;

  public config = config;

  @Input() hideHeader = false;

  public signOut() {
    this.userService.logout();
  }

  public changeInstance() {
    this.instance.clearSelectedInstance();
    this.router.navigate(['/server']);
  }

  public setTheme(theme: 'light' | 'dark' | 'auto') {
    this.themeService.setTheme(theme, true);
  }
}

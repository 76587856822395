import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

export enum SnackBarType {
  Error = 'error',
  CustomError = 'custom_error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Loading = 'loading',
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private snackBar = inject(MatSnackBar);

  public showError(err: HttpErrorResponse): MatSnackBarRef<SnackbarComponent> {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      data: {
        message: `${err.status}: ${err.statusText}`,
        type: SnackBarType.Error,
        show_button: false,
      },
    });
  }

  public showCustomError(message: string): MatSnackBarRef<SnackbarComponent> {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      data: {
        message,
        type: SnackBarType.CustomError,
        show_button: false,
      },
    });
  }

  public showSuccess(message: string) {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      data: {
        message,
        type: SnackBarType.Success,
        show_button: false,
      },
    });
  }

  public showInfo(message: string): MatSnackBarRef<SnackbarComponent> {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      data: {
        message,
        type: SnackBarType.Info,
        show_button: false,
      },
    });
  }

  public showLoading(message: string): MatSnackBarRef<SnackbarComponent> {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      data: {
        message,
        type: SnackBarType.Loading,
        show_button: false,
      },
    });
  }
}

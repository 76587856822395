<h1 mat-dialog-title>Upload files</h1>
<div mat-dialog-content>
  <ng-container *ngIf="!isUploading; else uploading">
    <input
      #fileInput
      type="file"
      multiple
      (change)="onFilesChanged($event)"
      name="images"
      required />
    <span [style.marginBottom.rem]="1" [style.display]="'block'">
      Please select DICOM files to upload.
      <b> You can select multiple files at once. </b>
    </span>
    <button
      type="button"
      mat-raised-button
      (click)="fileInput.click()"
      color="primary">
      Select files for upload
    </button>
    <ng-container
      *ngIf="!processing && (validFiles.length || invalidFiles.length)">
      <mat-list>
        <ng-container *ngIf="validFiles.length">
          <h4>Valid files</h4>
          <mat-list-item *ngFor="let file of validFiles">
            <mat-icon
              class="material-icons-round"
              matListItemIcon
              [style.color]="'var(--color-success)'"
              >check_circle</mat-icon
            >
            <div matListItemTitle>{{ file.name }}</div>
            <div matListItemLine>{{ file.size | fileSize }}</div>
          </mat-list-item>
        </ng-container>
        <ng-container *ngIf="invalidFiles.length">
          <mat-divider></mat-divider>
          <h4>Invalid files</h4>
          <p [style.marginBottom.px]="0">
            Only DICOM images can be uploaded. These files did not pass DICOM
            verification.
          </p>
          <mat-list-item *ngFor="let file of invalidFiles">
            <mat-icon
              class="material-icons-round"
              matListItemIcon
              [style.color]="'var(--color-warn)'"
              >block</mat-icon
            >
            <div matListItemTitle>{{ file.name }}</div>
            <div matListItemLine>
              <b>{{ file.type }}</b> | {{ file.size | fileSize }}
            </div>
          </mat-list-item>
        </ng-container>
      </mat-list>
      <ng-container *ngIf="validFiles.length">
        <p>
          <b [style.color]="'var(--color-success)'">Ready for upload.</b><br />
          {{ validFiles.length }} valid DICOM file{{
            validFiles.length !== 1 ? 's' : ''
          }}
          will be uploaded.<br />
          <span *ngIf="invalidFiles.length" [style.color]="'var(--color-warn)'">
            {{ invalidFiles.length }} invalid file{{
              invalidFiles.length !== 1 ? 's' : ''
            }}
            will not be uploaded.
          </span>
        </p>
        <ng-container
          *ngIf="userService.userPermissions$ | async as permissions">
          <mat-form-field
            *ngIf="permissions.upload_procedures_sites.length > 1">
            <mat-label>Upload to site:</mat-label>
            <mat-select [(ngModel)]="site">
              <mat-option
                *ngFor="let site of permissions.upload_procedures_sites"
                [value]="site.id">
                {{ site.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <mat-checkbox [(ngModel)]="acknowledgement" [style.marginTop.rem]="1">
          <b>
            I confirm that I have permission to upload this patient's data.
          </b>
        </mat-checkbox>
      </ng-container>
      <p *ngIf="invalidFiles.length && !validFiles.length">
        <b [style.color]="'var(--color-warn)'">No valid files.</b><br />
        {{ invalidFiles.length }} file{{ invalidFiles.length !== 1 ? 's' : '' }}
        selected, but none of them are valid DICOM files.
      </p>
    </ng-container>
  </ng-container>
</div>
<div mat-dialog-actions align="end">
  @if (isUploading) {
    <button type="button" mat-button color="warn" (click)="cancelUploads()">
      Cancel upload
    </button>
  } @else {
    <button mat-button mat-dialog-close>Cancel</button>
    <div
      [style.marginLeft.rem]="0.5"
      [matTooltip]="
        !acknowledgement
          ? 'You must confirm you have permission to upload before proceeding.'
          : ''
      "
      matTooltipPosition="above">
      <button
        mat-raised-button
        color="primary"
        [disabled]="processing || !validFiles.length || !acknowledgement"
        (click)="upload()">
        Upload
      </button>
    </div>
  }
</div>

<ng-template #uploading>
  <div class="uploads">
    <div
      class="upload"
      *ngFor="let file of validFiles"
      [ngClass]="{ error: file.error }">
      <p>
        <b [style.color]="'var(--color-primary)'">{{ file.name }}</b
        ><br />
        @if (file.error) {
          <b [style.color]="'var(--color-warn)'">Upload error.</b>
        } @else {
          {{ file.progress }}% complete.
        }
      </p>
      <mat-progress-bar mode="determinate" [value]="file.progress">
      </mat-progress-bar>
    </div>
  </div>
</ng-template>

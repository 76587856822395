import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IsDicomService {
  public isDicomFile(file: File): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const byteArray = new Uint8Array(reader.result as ArrayBuffer);

        // Check for the DICM prefix in the first 4 bytes
        const dicomPrefix = String.fromCharCode(...byteArray.slice(128, 132));
        const isDicom = dicomPrefix === 'DICM';

        resolve(isDicom);
      };

      reader.readAsArrayBuffer(file);
    });
  }
}

import {
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { ProceduresHttpService } from '../../procedures.http.service';
import {
  ApprovalUser,
  FormattedProcedure,
  Site,
  UserSite,
} from '../../procedures.model';
import { FormGroup, FormControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProcedureDetailsPanelComponent } from 'src/app/components/procedure-details-panel/procedure-details-panel.component';

export enum SendStudiesModalType {
  Send = 'send',
  Import = 'import',
}

@Component({
  selector: 'app-send-studies-modal',
  standalone: true,
  imports: [
    SharedModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatInputModule,
    ProcedureDetailsPanelComponent,
  ],
  templateUrl: './send-studies.modal.component.html',
  styleUrls: ['./send-studies.modal.component.scss'],
})
export class SendStudiesModalComponent implements OnInit {
  @ViewChild('siteInput')
  siteInput!: ElementRef<HTMLInputElement>;
  @ViewChild('userSiteInput')
  userSiteInput!: ElementRef<HTMLInputElement>;
  @ViewChild('userInput')
  userInput!: ElementRef<HTMLInputElement>;

  private proceduresHttpService = inject(ProceduresHttpService);
  private destroyRef = inject(DestroyRef);
  private dialogRef = inject(MatDialogRef<SendStudiesModalComponent>);
  private snackbar = inject(SnackbarService);

  SendStudiesModalType = SendStudiesModalType;

  public form = new FormGroup({
    site: new FormControl<string | Site>('', {
      validators: [Validators.required],
    }),
    approval_user: new FormControl<string | ApprovalUser>(''),
    delete_on_success: new FormControl<boolean>(false),
    acknowledge: new FormControl<boolean>(false, {
      validators: [Validators.requiredTrue],
    }),
    user_site: new FormControl<UserSite | null>(null),
  });

  public selectedSite: Site | undefined;
  public title!: string;
  public helperText!: string;
  public acknowledgementText!: string;
  public allSites: Site[] = [];
  public allUserSites: UserSite[] = [];
  public filteredSites: Site[] = [];
  public filteredUserSites: UserSite[] = [];
  public filteredUsers: ApprovalUser[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: SendStudiesModalType;
      procedure: FormattedProcedure;
      sites: Site[];
      can_delete: boolean;
      user_sites?: UserSite[];
    }
  ) {
    this.title = 'Transfer procedure';
    this.helperText =
      data.type === SendStudiesModalType.Send
        ? 'Select the site to transfer the following procedure to:'
        : 'Transfer the following procedure to your site:';
    this.acknowledgementText =
      data.type === SendStudiesModalType.Send
        ? `I confirm that I have permission to transfer this patient's data from ${data.procedure.site.name} to ${data.sites[0]}.`
        : `I confirm that I have permission to transfer this patient's data from ${
            data.procedure.site.name
          } to ${this.selectedSite?.name ?? 'the selected site.'}.`;
    this.allSites = data.sites;
    this.allUserSites = data.user_sites ?? [];
    this.filteredSites = this.allSites;
    this.filteredUserSites = this.allUserSites;
  }

  ngOnInit(): void {
    this.form.controls.site.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(site => {
        this.form.controls.approval_user.setValue('');

        if (typeof site === 'string' || site === null) return;

        this.form.controls.approval_user.setValidators(
          site.approval_required ? [Validators.required] : []
        );
        this.form.controls.approval_user.updateValueAndValidity();
        this.selectedSite = site;
      });

    if (this.data.sites.length === 1) {
      this.form.controls.site.setValue(this.data.sites[0], {
        emitEvent: true,
      });
      this.form.controls.delete_on_success.setValidators([]);
      this.form.controls.site.setValidators([]);
    }
    if (this.data.type === SendStudiesModalType.Import) {
      this.form.controls.user_site.setValidators([Validators.required]);
    }
  }

  public isSending = false;
  public async send() {
    if (!this.form.valid) return;
    const result = this.form.value;
    const siteId = Number((result.site as Site).id) ?? null;
    const approvalUserId =
      Number((result.approval_user as ApprovalUser)?.id) ?? null;
    this.isSending = true;
    this.proceduresHttpService
      .sendProcedure({
        procedure: this.data.procedure.id,
        to_site: siteId,
        approval_user: approvalUserId,
        delete: result.delete_on_success ?? false,
        acknowledge: result.acknowledge ?? false,
      })
      .pipe(take(1))
      .subscribe({
        next: response => {
          if (response.status === 201) {
            this.isSending = false;
            this.dialogRef.close(true);
            this.snackbar.showSuccess('Transfer request created.');
            return;
          }
          this.snackbar.showCustomError(
            `${response.status}: ${response.statusText}`
          );
        },
        error: (err: HttpErrorResponse) => {
          this.snackbar.showError(err);
        },
      });
  }

  public async import() {
    if (!this.form.valid) return;
    const result = this.form.value;
    if (!result.user_site) return;
    const approvalUserId =
      Number((result.approval_user as ApprovalUser)?.id) ?? null;
    this.isSending = true;
    this.proceduresHttpService
      .sendProcedure({
        procedure: this.data.procedure.id,
        to_site: result.user_site.id,
        approval_user: approvalUserId,
        delete: false,
        acknowledge: result.acknowledge ?? false,
      })
      .pipe(take(1))
      .subscribe({
        next: response => {
          if (response.status === 201) {
            this.isSending = false;
            this.dialogRef.close(true);
            this.snackbar.showSuccess('Transfer request created.');
            return;
          }
          console.error(response); // TODO: snackbar
        },
        error: err => {
          this.snackbar.showError(err);
        },
      });
  }

  public filterSites(): void {
    const filterValue = this.siteInput.nativeElement.value.toLowerCase();
    this.filteredSites = this.allSites.filter(site =>
      site.name.toLowerCase().includes(filterValue)
    );
  }

  public filterUserSites(): void {
    const filterValue = this.userSiteInput.nativeElement.value.toLowerCase();
    this.filteredUserSites = this.allUserSites.filter(site =>
      site.name.toLowerCase().includes(filterValue)
    );
  }

  public filterUsers(): void {
    const filterValue = this.userInput.nativeElement.value.toLowerCase();
    this.filteredUsers =
      this.selectedSite?.approval_users?.filter(user =>
        user.full_name.toLowerCase().includes(filterValue)
      ) ?? [];
  }

  public sitesDisplayFn(site: Site): string {
    return site && site.name ? site.name : '';
  }

  public userDisplayFn(user: ApprovalUser): string {
    return user && user.full_name ? user.full_name : '';
  }
}

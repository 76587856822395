import { DestroyRef, Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { AuthService, User } from '@auth0/auth0-angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpClient } from '@angular/common/http';
import { Endpoints, config } from '../consts';
import { SnackbarService } from './snackbar.service';
import { Router } from '@angular/router';

type MeUser = {
  id: number;
  email: string;
  full_name: string;
};

type ApprovalUser = {
  id: number;
  full_name: string;
};

type BriefSite = {
  id: number;
  name: string;
};

type SiteWithLinks = {
  id: number;
  name: string;
  sites_send_set: SiteWithApproval[];
  sites_request_set: SiteWithApproval[];
};

type SiteWithApproval = {
  id: number;
  name: string;
  approval_required: boolean;
  approval_users: ApprovalUser[];
};

export type UserPermissions = {
  user: MeUser;
  sites: SiteWithLinks[];
  admin_sites: BriefSite[];
  edit_procedures_sites: BriefSite[];
  delete_procedures_sites: BriefSite[];
  upload_procedures_sites: BriefSite[];
  send_procedures_sites: BriefSite[];
  receive_procedures_sites: BriefSite[];
  generate_link_for_procedures_sites: BriefSite[];
  retrieve_procedures_sites: BriefSite[];
  approve_send_in_sites: BriefSite[];
  approve_transfer_out_sites: BriefSite[];
  approve_retrieve_sites: BriefSite[];
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private auth = inject(AuthService);
  private destroyRef = inject(DestroyRef);
  private http = inject(HttpClient);

  public authUser$ = new BehaviorSubject<User | null>(null);
  public userPermissions$ = new BehaviorSubject<UserPermissions | null>(null);

  constructor() {
    this.auth.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => {
        if (user) {
          this.authUser$.next(user);
        }
      });
  }

  public logout(): void {
    this.auth.logout({ logoutParams: { returnTo: window.location.origin } });
  }

  public getMe(): Observable<UserPermissions> {
    return this.http.get<UserPermissions>(config.apiBaseUrl + Endpoints.AuthMe);
  }
}

import { SharedModule } from 'src/app/shared/shared.module';
import { Component, inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { SnackBarType } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-snackbar',
  standalone: true,
  imports: [SharedModule, MatSnackBarModule],
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  public snackBarRef = inject(MatSnackBarRef);

  public SnackBarType = SnackBarType;
  public data: { message: string; type: SnackBarType; show_button: boolean } =
    inject(MAT_SNACK_BAR_DATA);
}

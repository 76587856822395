<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
  <p>{{ helperText }}</p>
  <app-procedure-details-panel
    [procedure]="data.procedure"></app-procedure-details-panel>
  <form [formGroup]="form">
    <mat-form-field *ngIf="data.type !== SendStudiesModalType.Import">
      <mat-label>Transfer to site:</mat-label>
      <input
        #siteInput
        type="text"
        placeholder="Select site"
        matInput
        formControlName="site"
        [matAutocomplete]="auto"
        (input)="filterSites()"
        (focus)="filterSites()" />
      <mat-autocomplete
        requireSelection
        #auto="matAutocomplete"
        [displayWith]="sitesDisplayFn">
        <mat-option *ngFor="let site of filteredSites" [value]="site">
          {{ site.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field *ngIf="data.type === SendStudiesModalType.Import">
      <mat-label>Transfer to site:</mat-label>
      <input
        #userSiteInput
        type="text"
        placeholder="Select site"
        matInput
        formControlName="user_site"
        [matAutocomplete]="auto"
        (input)="filterUserSites()"
        (focus)="filterUserSites()" />
      <mat-autocomplete
        requireSelection
        #auto="matAutocomplete"
        [displayWith]="sitesDisplayFn">
        <mat-option *ngFor="let site of filteredUserSites" [value]="site">
          {{ site.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field *ngIf="selectedSite?.approval_required">
      <mat-label>Approving user:</mat-label>
      <input
        #userInput
        type="text"
        placeholder="Select user"
        matInput
        formControlName="approval_user"
        [matAutocomplete]="auto"
        (input)="filterUsers()"
        (focus)="filterUsers()" />
      <mat-autocomplete
        requireSelection
        #auto="matAutocomplete"
        [displayWith]="userDisplayFn">
        <mat-option *ngFor="let user of filteredUsers" [value]="user">
          {{ user.full_name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-checkbox formControlName="delete_on_success" *ngIf="data.can_delete">
      Delete procedure after successful transfer
    </mat-checkbox>
    <mat-checkbox formControlName="acknowledge" [style.marginTop.rem]="1">
      <b>
        I confirm that I have permission to transfer this patient's data from
        {{ data.procedure.site.name }}
        to
        {{
          data.type === SendStudiesModalType.Send
            ? this.selectedSite?.name || 'the selected site'
            : form.controls.user_site.value?.name || 'the selected site'
        }}.
      </b>
    </mat-checkbox>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!form.valid"
    (click)="data.type === SendStudiesModalType.Send ? send() : import()">
    Transfer
  </button>
</div>

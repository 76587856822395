import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransfersService {
  public trayState = new Subject<'open' | 'close' | 'toggle'>();

  public openTray(): void {
    this.trayState.next('open');
  }

  public closeTray(): void {
    this.trayState.next('close');
  }

  public toggleTray(): void {
    this.trayState.next('toggle');
  }
}

<mat-accordion>
  <mat-expansion-panel [style.marginBottom.rem]="1">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ procedure.patient_id }}
      </mat-panel-title>
      <mat-panel-description>
        {{ procedure.patient_name }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="procedure-details">
      <div>
        <h3>Patient details</h3>
        <p>
          <span><b>ID</b><br />{{ procedure.patient_id }}</span>
          <span><b>Name</b><br />{{ procedure.patient_name }}</span>
          <span><b>DOB</b><br />{{ procedure.f_patient_dob }}</span>
          <span *ngIf="procedure.patient_sex as sex"
            ><b>Sex</b><br />{{ sex }}</span
          >
        </p>
      </div>
      <div>
        <h3>Procedure details</h3>
        <p>
          <span *ngIf="procedure.accession_number as accession_number"
            ><b>Accession Number</b><br />{{ accession_number }}
          </span>
          <span><b>Date</b><br />{{ procedure.f_date }}</span>
          <span *ngIf="procedure.description as description"
            ><b>Description</b><br />{{ description }}</span
          >
          <span><b>Image count</b><br />{{ procedure.image_count }}</span>
          <span><b>Modality</b><br />{{ procedure.modality }}</span>
        </p>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

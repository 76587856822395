import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { ProceduresHttpService } from './procedures.http.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormattedProcedure,
  Procedure,
  ProcedureNotFromUserSite,
  Site,
} from './procedures.model';
import { SelectionModel } from '@angular/cdk/collections';
import {
  SendStudiesModalComponent,
  SendStudiesModalType,
} from './modal/send-studies-modal/send-studies.modal.component';
import { DateTime } from 'luxon';
import { TransfersComponent } from './transfers/transfers.component';
import { TransfersService } from './transfers/transfers.service';
import { BehaviorSubject, combineLatest, map, take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UploadComponent } from '../modal/upload/upload.component';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { UserPermissions, UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { InstanceService } from 'src/app/services/instance.service';
import { AuthConfigService } from '@auth0/auth0-angular';
import { Instance } from '../instance-selector/instance-selector.http.service';
import { ApiService } from 'src/app/services/api.service';
import { config } from 'src/app/consts';
import { GenerateLinkModalComponent } from './modal/generate-link-modal/generate-link.modal.component';

@Component({
  selector: 'app-procedures',
  standalone: true,
  imports: [
    SharedModule,
    MatTableModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatDialogModule,
    MatSidenavModule,
    TransfersComponent,
  ],
  templateUrl: './procedures.component.html',
  styleUrls: ['./procedures.component.scss'],
})
export class ProceduresComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer!: MatDrawer;

  private proceduresHttpService = inject(ProceduresHttpService);
  private dialog = inject(MatDialog);
  private transfersService = inject(TransfersService);
  private destroyRef = inject(DestroyRef);
  private snackbar = inject(SnackbarService);
  private confirmationDialog = inject(ConfirmationDialogService);
  public auth = inject(AuthService);
  private storage = inject(StorageService);
  private instanceService = inject(InstanceService);
  private router = inject(Router);
  private api = inject(ApiService);
  public userService = inject(UserService);

  public dataSource!: MatTableDataSource<FormattedProcedure>;
  public selection = new SelectionModel<FormattedProcedure>(true, []);

  public displayedColumns: string[] = [
    'select',
    'site',
    'patient_id',
    'patient_name',
    'f_patient_dob',
    'patient_sex',
    'accession_number',
    'f_date',
    'modality',
    'description',
  ];

  public loading = true;
  public procedures: FormattedProcedure[] = [];
  public sites: Site[] = [];

  public buttonVisibility = {
    edit: false,
    delete: false,
    send: false,
    generate: false,
    import: false,
  };

  public selectionChanged$ = this.selection.changed
    .pipe(takeUntilDestroyed())
    .subscribe(() => {
      if (this.selection.selected.length) {
        const userPermissions = this.userService.userPermissions$.value;
        const fromUserSite =
          this.selection.selected[0].procedure_is_from_user_site;
        if (!userPermissions) return;
        this.buttonVisibility = {
          edit:
            userPermissions?.edit_procedures_sites.some(
              site => site.id === this.selection.selected[0].site.id
            ) && fromUserSite,
          delete:
            userPermissions?.delete_procedures_sites.some(
              site => site.id === this.selection.selected[0].site.id
            ) && fromUserSite,
          send:
            userPermissions?.send_procedures_sites.some(
              site => site.id === this.selection.selected[0].site.id
            ) &&
            fromUserSite &&
            (userPermissions?.sites.find(
              site => this.selection.selected[0].site.id === site.id
            )?.sites_send_set.length ?? 0) > 0,
          generate:
            userPermissions?.generate_link_for_procedures_sites.some(
              site => site.id === this.selection.selected[0].site.id
            ) && fromUserSite,
          import:
            !fromUserSite &&
            (
              this.selection.selected[0] as ProcedureNotFromUserSite
            ).site.sites_see.some(site => {
              return userPermissions?.receive_procedures_sites.some(
                permissionSite => permissionSite.id === site.id
              );
            }),
        };
      }
    });
  private sub = this.transfersService.trayState.subscribe(state => {
    if (state === 'open') {
      this.drawer.open();
      return;
    }
    if (state === 'toggle') {
      this.drawer.toggle();
      return;
    }
    this.drawer.close();
  });

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    // make sure api base url is set
    if (!config.apiBaseUrl.length) {
      this.router.navigate(['/server']);
      return;
    }
    this.userService
      .getMe()
      .pipe(take(1))
      .subscribe({
        next: userPermissions => {
          this.userService.userPermissions$.next(userPermissions);
          this.registerLoaded();
        },
        error: () => {
          this.router.navigate(['/server']);
          this.snackbar.showCustomError(
            'An unknown error has occured. Please select a server and try again.'
          );
        },
      });
    this.getProcedures();
  }

  private getProcedures() {
    this.selection.clear();
    this.proceduresHttpService
      .getProcedures()
      .pipe(take(1))
      .subscribe({
        next: unformattedProcedures => {
          this.setProcedures(unformattedProcedures);
        },
        error: (err: HttpErrorResponse) => {
          this.snackbar.showError(err);
        },
      });
  }

  private loaded = 0;
  private registerLoaded() {
    this.loaded++;
    if (this.loaded === 2) {
      this.loading = false;
    }
  }

  private setProcedures(unformattedProcedures: Procedure[]) {
    this.procedures = unformattedProcedures.map(procedure => {
      const formattedDate = DateTime.fromFormat(procedure.date, 'yyyyMMdd');
      const formattedPatientDOB = DateTime.fromFormat(
        procedure.patient_dob,
        'yyyyMMdd'
      );
      const formattedProcedure: FormattedProcedure = {
        ...procedure,
        f_patient_dob: formattedPatientDOB.isValid
          ? formattedPatientDOB.toFormat('dd/MM/yyyy')
          : procedure.patient_dob || 'Unknown',
        f_date: formattedDate.isValid
          ? formattedDate.toFormat('dd/MM/yyyy')
          : procedure.date || 'Unknown',
      };
      return formattedProcedure;
    });

    this.dataSource = new MatTableDataSource<FormattedProcedure>(
      this.procedures
    );
    this.registerLoaded();
    this.refreshing = false;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: FormattedProcedure): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} ${
      row.patient_name
    }`;
  }

  public async openSendModal() {
    const userPermissions = this.userService.userPermissions$.value;
    if (!userPermissions) return;
    const userSendableSites =
      userPermissions.sites.find(
        site => site.id === this.selection.selected[0].site.id
      )?.sites_send_set ?? [];
    if (!userSendableSites?.length) {
      // Show error = no sites to send to
      return;
    }

    const dialogRef = this.dialog.open(SendStudiesModalComponent, {
      data: {
        type: SendStudiesModalType.Send,
        procedure: this.selection.selected[0],
        sites: userSendableSites,
        can_delete: userPermissions.delete_procedures_sites.some(
          site => site.id === this.selection.selected[0].site.id
        ),
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result) {
          this.selection.clear();
          this.refresh();
          // TODO: open transfers and load them
          this.snackbar.showSuccess('Transfer submitted');
        }
      });
  }

  public openImportModal() {
    const procedure = this.selection.selected[0];
    const userPermissions = this.userService.userPermissions$.value;
    if (!userPermissions) return;
    const userSites = userPermissions.receive_procedures_sites.filter(site => {
      return (procedure as ProcedureNotFromUserSite).site.sites_see.some(
        permissionSite => permissionSite.id === site.id
      );
    });
    const dialogRef = this.dialog.open(SendStudiesModalComponent, {
      data: {
        type: SendStudiesModalType.Import,
        procedure,
        sites: [procedure.site as Site],
        can_delete: false,
        user_sites: userSites,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result) {
          this.selection.clear();
          this.refresh();
          // TODO: open transfers and load them
          this.snackbar.showSuccess('Transfer submitted');
        }
      });
  }

  public openGenerateLinkModal() {
    const procedure = this.selection.selected[0];
    const dialogRef = this.dialog.open(GenerateLinkModalComponent, {
      data: {
        procedure,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result) {
          this.snackbar.showSuccess('Procedure link was sent successfully.');
        }
      });
  }

  public refreshing = false;
  public async refresh() {
    this.refreshing = true;
    this.getProcedures();
  }

  public toggleRow(row: FormattedProcedure) {
    if (this.selection.isSelected(row)) {
      this.selection.deselect(row);
      return;
    }
    if (this.selection.selected.length) {
      this.selection.clear();
    }
    this.selection.select(row);
  }

  public openUploadModal() {
    const dialogRef = this.dialog.open(UploadComponent);

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        if (result) {
          this.selection.clear();
          this.refresh();
        }
      });
  }

  public delete() {
    this.confirmationDialog
      .confirm({
        title: 'Delete procedure',
        message:
          'Are you sure you want to delete this procedure? This action cannot be undone and it will be removed from the DICOM Hub immediately.',
        isDestructive: true,
        cancelButton: 'Cancel',
        confirmButton: 'Delete',
      })
      .subscribe((result: boolean) => {
        if (result) {
          const loadingRef = this.snackbar.showLoading('Deleting procedure');
          this.proceduresHttpService
            .deleteProcedure(this.selection.selected[0].id)
            .pipe(take(1))
            .subscribe({
              next: response => {
                if (response.status === 204) {
                  loadingRef.dismiss();
                  this.snackbar.showSuccess('Procedure deleted');
                  this.refresh();
                  return;
                }
                this.snackbar.showCustomError(
                  'Something went wrong, please try again'
                );
              },
            });
        }
      });
  }
}

import { Injectable, inject } from '@angular/core';
import { Instance } from '../components/instance-selector/instance-selector.http.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InstanceService {
  public storage = inject(StorageService);
  private user = inject(UserService);
  private api = inject(ApiService);
  private router = inject(Router);

  public setSelectedInstance(instance: Instance): void {
    this.storage.setData('DICOM_INSTANCE', instance.stub);
    this.api.setApiBaseUrl(instance);
    this.router.navigate(['/procedures']);
  }

  public clearSelectedInstance(): void {
    this.storage.deleteData('DICOM_INSTANCE');
  }

  public getSelectedInstance(): string | undefined | null {
    return this.storage.getData<string>('DICOM_INSTANCE');
  }
}

import { Injectable, inject } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpResponseBase,
  HttpUploadProgressEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Endpoints } from 'src/app/consts';
import { config } from 'src/app/consts';

@Injectable({
  providedIn: 'root',
})
export class UploadHttpService {
  private http = inject(HttpClient);

  public getUploadURL(params: {
    acknowledge: boolean;
    site: number | null;
  }): Observable<string> {
    return this.http.post<string>(
      config.apiBaseUrl + Endpoints.Procedures,
      params
    );
  }

  public postUpload(
    params: File,
    url: string,
    blobName: string
  ): Observable<HttpEvent<HttpUploadProgressEvent | HttpResponseBase>> {
    const splitUrl = url.split('?');
    url = `${splitUrl[0]}/${blobName}?${splitUrl[1]}`;
    return this.http.put<HttpUploadProgressEvent | HttpResponseBase>(
      url,
      params,
      {
        observe: 'events',
        reportProgress: true,
        headers: {
          'x-ms-blob-type': 'BlockBlob',
        },
      }
    );
  }
}

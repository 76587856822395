import { Instance } from './components/instance-selector/instance-selector.http.service';

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum Endpoints {
  AuthMe = '/auth/me/',
  Procedures = '/procedures/',
  Transfers = '/transfers/',
  Settings = '/settings/',
}

export const config: {
  apiBaseUrl: string;
  instance: Instance | undefined;
} = {
  apiBaseUrl: '',
  instance: undefined,
};

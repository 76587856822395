import { Component, OnInit, inject, signal } from '@angular/core';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  InstanceSelectorHttpService,
  Instance,
} from './instance-selector.http.service';
import { BehaviorSubject, scan, take } from 'rxjs';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { InstanceService } from 'src/app/services/instance.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-instance-selector',
  standalone: true,
  imports: [SharedModule, MatCard, MatCardContent, MatCardHeader, MatCardTitle],
  templateUrl: './instance-selector.component.html',
  styleUrl: './instance-selector.component.scss',
})
export class InstanceSelectorComponent implements OnInit {
  private instanceSelectorHttpService = inject(InstanceSelectorHttpService);
  public user = inject(UserService);
  private snackbar = inject(SnackbarService);
  private instanceService = inject(InstanceService);
  private utils = inject(UtilsService);

  public instances$ = new BehaviorSubject<Instance[]>([]);
  public loading = signal(true);

  ngOnInit(): void {
    const user = this.user.authUser$?.value;
    if (!user) {
      this.snackbar.showCustomError('User not found, please sign in.');
      return;
    }

    const instanceList: string[] = user['instances'];
    let savedInstance = this.instanceService.getSelectedInstance();

    if (savedInstance && !instanceList.includes(savedInstance)) {
      this.snackbar.showCustomError(
        'You no longer have access to your last accessed server.'
      );
      this.instanceService.clearSelectedInstance();
      savedInstance = null;
    }

    // TODO: Settings call to savedInstance first to prevent lots of calls every refresh

    if (!savedInstance) {
      this.loading.set(false);
    }

    if (instanceList.length === 1 || savedInstance) {
      this.instanceSelectorHttpService
        .getInstance(savedInstance || instanceList[0])
        .pipe(take(1))
        .subscribe({
          next: async instance => {
            this.setInstance(instance);
          },
          error: () => {
            this.loading.set(false);
            this.snackbar.showCustomError(
              `Your${instanceList.length === 1 ? '' : ' last accessed'} server has encountered an error. Please refresh and try again.`
            );
            this.instanceService.clearSelectedInstance();
            this.iterateInstances(instanceList);
          },
        });
    } else {
      this.iterateInstances(instanceList);
    }
  }

  private iterateInstances(instanceList: string[]) {
    this.instances$.next(
      instanceList.map(instanceStub => {
        return {
          name: '',
          logo: '',
          last_edited_date: '',
          url: '',
          stub: instanceStub,
          loading: true,
        };
      })
    );

    if (instanceList.length) {
      instanceList.forEach(instanceStub => {
        this.instanceSelectorHttpService
          .getInstance(instanceStub)
          .pipe(take(1))
          .subscribe({
            next: async instance => {
              const isValidImage = await this.utils.isValidImageUrl(
                instance.logo
              );
              if (!isValidImage) {
                instance.logo = 'logo.png';
              }
              this.instances$.next(
                [
                  ...this.instances$.value.filter(
                    instance => instance.stub !== instanceStub
                  ),
                  instance,
                ].sort((a, b) => a.name.localeCompare(b.name))
              );
            },
            error: () => {
              this.instances$.next(
                [
                  ...this.instances$.value.filter(
                    instance => instance.stub !== instanceStub
                  ),
                  {
                    name: instanceStub.toUpperCase() + ' (Error)',
                    logo: 'logo_error.png', // some kind of error logo
                    last_edited_date: '',
                    url: '',
                    stub: instanceStub,
                    error: true,
                  },
                ].sort((a, b) => a.name.localeCompare(b.name))
              );
            },
          });
      });
    }
  }

  setInstance(instance: Instance): void {
    if (!instance.url || instance.error || instance.loading || !instance.name) {
      this.snackbar.showCustomError(
        'There was a problem with the selected server. Please refresh the page and try again.'
      );
      return;
    }
    this.loading.set(false);
    this.instanceService.setSelectedInstance(instance);
  }
}

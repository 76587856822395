import { Component, OnInit, inject } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedModule } from 'src/app/shared/shared.module';
import { TransfersService } from './transfers.service';
import { TransfersHttpService } from './transfers.http.service';
import { Transfer, TransferStatus } from '../procedures.model';
import { MatCardModule } from '@angular/material/card';
import { take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-transfers',
  standalone: true,
  imports: [
    SharedModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatCardModule,
  ],
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.scss'],
})
export class TransfersComponent implements OnInit {
  private transfersService = inject(TransfersService);
  private transfersHttp = inject(TransfersHttpService);
  private snackbar = inject(SnackbarService);

  public loading = true;
  public transfers!: Transfer[];
  public TransferStatus = TransferStatus;

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.getTransfers();
  }

  public closeTray(): void {
    this.transfersService.closeTray();
  }

  private async getTransfers(): Promise<void> {
    this.transfersHttp
      .getTransfers()
      .pipe(take(1))
      .subscribe({
        next: transfers => {
          transfers.forEach(transfer => {
            if (transfer.procedure.image_count) {
              switch (transfer.status) {
                case TransferStatus.InProgress:
                  transfer.progress_percent = Math.floor(
                    (transfer.progress_count /
                      (transfer.procedure.image_count ||
                        transfer.progress_count)) *
                      100
                  );
                  break;
                default:
                  transfer.progress_percent = 100;
                  break;
              }
            }
            transfer.relative_time =
              DateTime.fromISO(transfer.created_date).toRelative() || '';
          });
          this.transfers = transfers;
          this.loading = false;
        },
        error: (err: HttpErrorResponse) => {
          this.snackbar.showError(err);
          this.loading = false;
        },
      });
  }

  public refreshTransfers() {
    this.loading = true;
    this.getTransfers();
  }
}

import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterEventsService {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  public routeData = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(() => this.route),
    map(route => {
      while (route.firstChild) route = route.firstChild;
      return route;
    }),
    filter(route => route.outlet === 'primary'),
    mergeMap(route => route.data)
  );
}

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Endpoints } from 'src/app/consts';
import { Transfer } from '../procedures.model';
import { config } from 'src/app/consts';

@Injectable({
  providedIn: 'root',
})
export class TransfersHttpService {
  private http = inject(HttpClient);

  public getTransfers(): Observable<Transfer[]> {
    return this.http.get<Transfer[]>(
      config.apiBaseUrl + Endpoints.Transfers
    );
  }
}

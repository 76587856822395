<ng-container *ngIf="config.apiBaseUrl.length">
  <ng-container *ngIf="this.auth.user$ | async as user">
    <mat-toolbar *ngIf="!hideHeader">
      <div class="header-left">
        <img src="logo.png" alt="" />
        <h1 [style.lineHeight]="'1'">
          DICOM Hub<br /><span [style.opacity]="'0.75'"
            ><small>{{ config.instance?.name }}</small></span
          >
        </h1>
      </div>
      <span class="spacer"></span>
      <button
        mat-icon-button
        aria-label="Profile"
        matTooltip="View profile"
        [matMenuTriggerFor]="headerMenu">
        <mat-icon class="material-icons-round">more_vert</mat-icon>
      </button>
      <mat-menu #headerMenu="matMenu">
        <div class="theme-toggle">
          <mat-button-toggle-group
            (change)="setTheme($event.value)"
            (click)="$event.stopPropagation()">
            <mat-button-toggle
              value="auto"
              [checked]="currentTheme$.value === 'auto'">
              Auto
            </mat-button-toggle>
            <mat-button-toggle
              value="light"
              [checked]="currentTheme$.value === 'light'">
              <mat-icon class="material-icons-round">light_mode</mat-icon>
              Light
            </mat-button-toggle>
            <mat-button-toggle
              value="dark"
              [checked]="currentTheme$.value === 'dark'">
              <mat-icon class="material-icons-round">dark_mode</mat-icon>
              Dark
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <button
          mat-menu-item
          (click)="changeInstance()"
          *ngIf="user['instances'].length > 1">
          <mat-icon class="material-icons-round">dns</mat-icon>
          <span>Change server</span>
        </button>
        <button mat-menu-item (click)="transfersService.toggleTray()">
          <mat-icon class="material-icons-round">swap_horiz</mat-icon>
          <span>Transfers</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-round">link</mat-icon>
          <span>Links</span>
        </button>
        <ng-container
          *ngIf="userService.userPermissions$ | async as userPermissions">
          @if (userPermissions.approve_send_in_sites.length || userPermissions.approve_transfer_out_sites.length) {
            <button mat-menu-item>
              <mat-icon class="material-icons-round">done_all</mat-icon>
              <span>Approvals</span>
            </button>
          }
        </ng-container>
        <ng-container
        *ngIf="userService.userPermissions$ | async as userPermissions">
        @if (userPermissions.admin_sites.length) {
          <button mat-menu-item>
            <mat-icon class="material-icons-round">settings</mat-icon>
            <span>Site management</span>
          </button>
        }
        </ng-container>
        <button mat-menu-item>
          <mat-icon class="material-icons-round">account_circle</mat-icon>
          <span>Profile</span>
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons-round">help</mat-icon>
          <span>Help</span>
        </button>
        <button mat-menu-item (click)="signOut()">
          <mat-icon class="material-icons-round">logout</mat-icon>
          <span>Sign out</span>
        </button>
      </mat-menu>
    </mat-toolbar>
  </ng-container>
</ng-container>

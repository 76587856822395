import { Component, Input } from '@angular/core';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormattedProcedure } from '../procedures/procedures.model';

@Component({
  selector: 'app-procedure-details-panel',
  standalone: true,
  imports: [
    SharedModule,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
  ],
  templateUrl: `./procedure-details-panel.component.html`,
  styleUrl: './procedure-details-panel.component.scss',
})
export class ProcedureDetailsPanelComponent {
  @Input({ required: true }) procedure!: FormattedProcedure;
}

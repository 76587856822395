import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Endpoints } from 'src/app/consts';
import { AuthConfigService } from '@auth0/auth0-angular';

export interface Instance {
  name: string;
  logo: string;
  last_edited_date: string;
  stub: string;
  url: string;
  error?: boolean;
  loading?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class InstanceSelectorHttpService {
  private http = inject(HttpClient);
  private authConfig = inject(AuthConfigService);

  public getInstance(instance: string): Observable<Instance> {
    const allowedList = this.authConfig.httpInterceptor?.allowedList;
    let newUrl = `https://${instance}-api.dicomhub.io`;
    if (instance === 'local') {
      newUrl = `https://${instance}-api.dicomhub.io:8000`;
    }
    if (allowedList && !allowedList.includes(newUrl + '/settings/')) {
      allowedList.push(newUrl + '/settings/');
    }
    console.log(allowedList);
    return this.http.get<Instance>(newUrl + Endpoints.Settings);
  }
}

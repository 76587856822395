<mat-drawer-container autosize>
  <mat-drawer #drawer mode="side" position="end">
    <app-transfers *ngIf="drawer?.opened"></app-transfers>
  </mat-drawer>

  <div class="content" *ngIf="!loading; else loader">
    <mat-toolbar [style.width]="drawer?.opened ? 'calc(100% - 360px)' : '100%'">
      <ng-container
        *ngIf="userService.userPermissions$ | async as userPermissions">
        <div class="button-group">
          <ng-container>
            <button
              *ngIf="userPermissions.upload_procedures_sites.length"
              mat-raised-button
              color="primary"
              matTooltip="Upload"
              (click)="openUploadModal()">
              <mat-icon class="material-icons-round">upload</mat-icon>
              <span>Upload</span>
            </button>
            <button
              *ngIf="userPermissions.retrieve_procedures_sites.length"
              mat-stroked-button
              matTooltip="Retrieve"
              color="primary">
              <mat-icon class="material-icons-round">download</mat-icon>
              <span>Retrieve</span>
            </button>
          </ng-container>
        </div>
        <span class="spacer"></span>
        <div class="button-group">
          <ng-container *ngIf="selection.selected.length">
            <button
              *ngIf="buttonVisibility.send"
              mat-raised-button
              color="primary"
              matTooltip="Transfer"
              (click)="openSendModal()">
              <mat-icon class="material-icons-round">sync_alt</mat-icon>
              <span>Transfer</span>
            </button>
            <button
              *ngIf="buttonVisibility.generate"
              mat-stroked-button
              color="primary"
              matTooltip="Generate link"
              (click)="openGenerateLinkModal()">
              <mat-icon class="material-icons-round">link</mat-icon>
              <span>Generate link</span>
            </button>
            <button
              *ngIf="buttonVisibility.edit"
              mat-stroked-button
              color="primary"
              matTooltip="Edit">
              <mat-icon class="material-icons-round">edit</mat-icon>
              <span>Edit</span>
            </button>
            <button
              *ngIf="buttonVisibility.delete"
              mat-stroked-button
              color="warn"
              matTooltip="Delete"
              (click)="delete()">
              <mat-icon class="material-icons-round">delete</mat-icon>
              <span>Delete</span>
            </button>
            <button
              *ngIf="buttonVisibility.import"
              mat-raised-button
              color="primary"
              matTooltip="Transfer"
              (click)="openImportModal()">
              <mat-icon class="material-icons-round">sync_alt</mat-icon>
              <span>Transfer</span>
            </button>
          </ng-container>
          <button
            mat-icon-button
            aria-label="Refresh procedures"
            matTooltip="Refresh procedures"
            (click)="refresh()">
            <mat-icon
              class="material-icons-round"
              [ngClass]="{ 'spin-med': refreshing }"
              >refresh</mat-icon
            >
          </button>
        </div>
      </ng-container>
    </mat-toolbar>
    <table
      *ngIf="!loading; else loader"
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="toggleRow(row)"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- Position Column -->
      <ng-container matColumnDef="site">
        <th mat-header-cell *matHeaderCellDef>Site</th>
        <td mat-cell *matCellDef="let column">{{ column.site.name }}</td>
      </ng-container>
      <ng-container matColumnDef="patient_id">
        <th mat-header-cell *matHeaderCellDef>Patient ID</th>
        <td mat-cell *matCellDef="let column">{{ column.patient_id }}</td>
      </ng-container>
      <ng-container matColumnDef="patient_name">
        <th mat-header-cell *matHeaderCellDef>Patient name</th>
        <td mat-cell *matCellDef="let column">{{ column.patient_name }}</td>
      </ng-container>
      <ng-container matColumnDef="f_patient_dob">
        <th mat-header-cell *matHeaderCellDef>Patient DOB</th>
        <td mat-cell *matCellDef="let column">{{ column.f_patient_dob }}</td>
      </ng-container>
      <ng-container matColumnDef="patient_sex">
        <th mat-header-cell *matHeaderCellDef>Patient sex</th>
        <td mat-cell *matCellDef="let column">
          <ng-container [ngSwitch]="column.patient_sex">
            <ng-container *ngSwitchCase="'M'">
              <mat-icon class="material-icons-round" [style.color]="'blue'"
                >male</mat-icon
              >
            </ng-container>
            <ng-container *ngSwitchCase="'F'">
              <mat-icon class="material-icons-round" [style.color]="'pink'"
                >female</mat-icon
              >
            </ng-container>
            <ng-container *ngSwitchDefault>
              <mat-icon class="material-icons-round" [style.color]="'grey'"
                >question_mark</mat-icon
              >
            </ng-container>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="accession_number">
        <th mat-header-cell *matHeaderCellDef>Accession Number</th>
        <td mat-cell *matCellDef="let column">{{ column.accession_number }}</td>
      </ng-container>
      <ng-container matColumnDef="f_date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let column">{{ column.f_date }}</td>
      </ng-container>
      <ng-container matColumnDef="modality">
        <th mat-header-cell *matHeaderCellDef>Modality</th>
        <td mat-cell *matCellDef="let column">{{ column.modality }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let column">{{ column.description }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="toggleRow(row)"></tr>
    </table>
  </div>
</mat-drawer-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProceduresComponent } from './components/procedures/procedures.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { authGuardFn } from '@auth0/auth0-angular';
import { InstanceSelectorComponent } from './components/instance-selector/instance-selector.component';

const routes: Routes = [
  { path: '', redirectTo: '/procedures', pathMatch: 'full' },
  {
    path: 'procedures',
    component: ProceduresComponent,
    canActivate: [authGuardFn],
  },
  {
    path: 'server',
    component: InstanceSelectorComponent,
    canActivate: [authGuardFn],
    data: { hideHeader: true },
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable, inject } from '@angular/core';
import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private storage = inject(StorageService);

  public currentTheme$ = new BehaviorSubject<'light' | 'dark' | 'auto'>('auto');

  public initTheme() {
    const storedTheme = this.storage.getData<'light' | 'dark' | 'auto'>(
      'THEME'
    );
    if (!storedTheme) {
      this.setTheme('auto', true);
    } else {
      this.currentTheme$.next(storedTheme);
      this.setTheme(this.currentTheme$.value);
    }
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', event => {
      if (this.currentTheme$.value !== 'auto') return;
      event.matches ? this.setTheme('dark') : this.setTheme('light');
    });
  }

  public setTheme(
    theme: 'light' | 'dark' | 'auto',
    fromUI: boolean = false
  ): void {
    switch (theme) {
      case 'light':
        document.body.classList.remove('dark-theme');
        break;
      case 'dark':
        document.body.classList.add('dark-theme');
        break;
      case 'auto':
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? this.setTheme('dark')
          : this.setTheme('light');
        break;
    }
    if (fromUI) {
      this.currentTheme$.next(theme);
      this.storeTheme(theme);
    }
  }

  private storeTheme(theme: 'light' | 'dark' | 'auto') {
    this.storage.setData('THEME', theme);
  }
}

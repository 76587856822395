import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HeaderComponent,
    AuthModule.forRoot({
      domain: 'dev-dicomhub.au.auth0.com',
      clientId: 'lAcPAuQBsw2kuLIX37GV54RYfYvIPql0',
      authorizationParams: {
        audience: 'https://dev-api.dicomhub.io',
        redirect_uri: window.location.origin,
      },
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [],
      },
    }),
  ],
  providers: [
    importProvidersFrom(MatDialogModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    MatSnackBar,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { RouterEventsService } from './services/router-events.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@auth0/auth0-angular';
import { ThemeService } from './services/theme.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public hideHeader = false;

  private title = inject(Title);
  public auth = inject(AuthService);
  private themeService = inject(ThemeService);
  private destroyRef = inject(DestroyRef);

  private sub = inject(RouterEventsService).routeData.subscribe(data => {
    this.hideHeader = data['hideHeader'] || false;
  });

  ngOnInit(): void {
    this.title.setTitle('DICOM Hub');

    //wait until auth0 is ready
    this.auth.isAuthenticated$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.auth.user$.pipe(take(1)).subscribe(user => {
            if (user) {
              this.themeService.initTheme();
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
